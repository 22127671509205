<template>
  <div class="order-search">
    <el-form ref="form" :model="form" label-width="80px" inline>
      <el-form-item label="订单号：">
        <el-input v-model="form.orderId" placeholder="请输入KF开头或VT开头的订单号" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="getOrderInfo">查询</el-button>
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <div style="padding-top: 20px">
      <el-table border style="width: 100%" :data="orderList" v-if="orderList.length" v-loading="loading">
        <el-table-column prop="apiorder_id" label="订单号" align="center" width="200px">
          <template slot-scope="{row}">
            <span v-html="`${row.order_id}`" style="font-size: 12px"></span>
          </template>
        </el-table-column>
        <el-table-column prop="order_money" label="订单金额(元)" align="center"></el-table-column>
        <el-table-column prop="order_price" label="支付金额(元)" align="center"></el-table-column>
        <el-table-column prop="user_id" label="用户ID" align="center"></el-table-column>
        <el-table-column prop="user_name" label="用户名称" align="center"></el-table-column>
        <el-table-column prop="sub_time" label="创建时间" align="center">
          <template slot-scope="{row}">
            {{parseTime(row.sub_time)}}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="订单状态" align="center">
          <template slot-scope="{row}">
            {{returnOrderStatus(row.status)}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="{row}" v-if="Number(row.status) === 0">
            <el-button type="text" @click="supplementaryOrder(row)">补单</el-button>
          </template>
        </el-table-column>
      </el-table>
        <p class="pagination" v-if="orderList.length">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="limit"
            :current-page="page"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </p>
      <el-empty v-else description="无数据"></el-empty>
    </div>
  </div>
</template>

<script>
import {parseTime} from "@/utils";
import {get_pay_service_order_list, supplementary_order} from "@/api/code";

export default {
  data(){
    return {
      form:{},
      orderList:[],
      loading: false,
      total:0,
      page:1,
      limit:10,
    }
  },
  mounted() {
    this.getOrderInfo()
  },
  methods:{
    parseTime,
    handleCurrentChange(currentPage){
      this.page = currentPage
      this.getOrderInfo()
    },
    async getOrderInfo(){
      try{
        this.loading = true
        const queryParams = {
          page: this.page,
          limit: this.limit
        }
        if(this.form.orderId) queryParams.order_id = this.form.orderId
        const res = await get_pay_service_order_list(queryParams)
        this.orderList = res.data.list
        this.total = Number(res.data.count)
        this.loading = false
      }catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    returnOrderStatus(status){
      const orderStatus = parseInt(status)
      switch (orderStatus){
        case 0:
          return '已失效'
        case 1:
          return '支付中'
        case 2:
          return '支付成功'
        default:
          return '未知'
      }
    },
    supplementaryOrder(row){
      this.$confirm('是否确认该用户已支付?', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try{
          const res = await supplementary_order({order_id: row.order_id})
          if(res.code === 200){
            this.loading = true
            setTimeout(async ()=>{
              this.loading = false
              this.$message.success(res.msg)
              await this.getOrderInfo()
            },3000)
          }
        }catch (e) {
          console.log(e)
        }
      })
    }
  },
  sockets: {
    offLine(value) {
      console.log('offLine', value)
      this.$socket.emit('close')
      this.$socket.disconnect()
      this.$router.push('/')
    },
    msg(value) { // 消息
      console.log('msg 新消息：', value)
      if (value.code === 0 && value.data.formUid) {
        this.handlePlay()
        // 缓存消息
        this.setImMsgList(value.data)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.pagination{
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
